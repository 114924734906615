import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../sass/homeCarousel.css';
import imageFirst from '../img/carusel/1.webp';
import imageSecond from '../img/carusel/2.webp';
import imageThird from '../img/carusel/3.webp';
import imageFourth from '../img/carusel/4.webp';
import imageFifth from '../img/carusel/5.webp';
import imageSixth from '../img/carusel/6.webp';
import imageSeventh from '../img/carusel/7.webp';
import imageEight from '../img/carusel/8.webp';

import partnerFirst from '../img/partners/MP.svg';
import partnerSecond from '../img/partners/logoEurocup3.svg';
import partnerThird from '../img/partners/logoSWC.svg';
import CustomArrow from "./technicalComponents/CustomArrow";

    const MyCarousel = () => {
        const PrevArrow = (props) => (
            <CustomArrow {...props} className="slick-prev" style={{ left: '25px' }} />
        );
        const NextArrow = (props) => (
            <CustomArrow {...props} className="slick-next" style={{ right: '25px' }} />
        );

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: false,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />
        };


    return (
        <div>
            <div className="carouselContainer">
                <Slider {...settings}>
                    <div>
                        <img className="carouselImg" src={imageFirst} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "top"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageSecond} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "bottom"}} />
                    </div>
                    <div>
                        <img className="carouselImg" src={imageThird} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageFourth} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageFifth} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageSixth} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "bottom"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageSeventh} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "bottom"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageEight} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                </Slider>
                <div className="carousel-logo">
                    <a href="https://www.mpmotorsport.com/" target="_blank" rel="noopener noreferrer">
                        <img src={partnerFirst} alt="MP accelerating talent"/>
                    </a>
                    <a href="https://eurocup3.org/" target="_blank" rel="noopener noreferrer">
                        <img src={partnerSecond} alt="Eurocup3"/>
                    </a>
                        <img className="swcHome" src={partnerThird} alt="Spanish winter championship"/>
                </div>
            </div>
            <div className="carousel-logoMobile">
                <a href="https://www.mpmotorsport.com/" target="_blank" rel="noopener noreferrer">
                    <img src={partnerFirst} alt="MP accelerating talent"/>
                </a>
                <a href="https://eurocup3.org/" target="_blank" rel="noopener noreferrer">
                    <img src={partnerSecond} alt="Eurocup3"/>
                </a>
                    <img src={partnerThird} alt="Spanish winter championship"/>
            </div>
        </div>
    );
    };

export default MyCarousel;

