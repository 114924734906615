import React from "react";
import '../sass/season.css';
import CustomH2 from "../components/technicalComponents/CustomH2";

import franceFlag from '../img/flag/franceFlag.svg';
import spainFlag from '../img/flag/spainFlag.svg';
import portugalFlag from '../img/flag/portugalFlag.svg';
import italyFlag from '../img/flag/italyFlag.svg';
import netherladsFlag from '../img/flag/netherlandsFlag.svg';
import belgiumFlag from '../img/flag/belgiumFlag.svg';
import austriaFlag from '../img/flag/austriaFlag.svg';
import eurocup3 from '../img/partners/logoEurocup3.svg';
import swc from '../img/partners/logoSWC.svg';
import {useTranslation} from "react-i18next";


const Season = () => {

    const {t} = useTranslation();
    return (
        <div className="seasonContainer">
            <div className="h2Wrapper">
                <CustomH2 text={t("Title")}/>
            </div>
            <div className="contentBox">
                <div className="flexContainer">
                    <img className="eventLogo swc" src={swc} alt="SWC"/>
                    <div className="eventPart">
                        <div className="eventWrapper">
                            <img src={spainFlag} alt="spain flag"/><p>07-09.02.2025 Circuito de Jerez Ángel Nieto</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={portugalFlag} alt="portugal flag"/><p>21-23.02.2025 Autódromo Internacional do
                            Algarve</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={spainFlag} alt="spain flag"/><p>28-30.03.2025 circuito de motorland aragón</p>
                        </div>
                    </div>
                </div>
                <div className="flexContainer">
                    <img className="eventLogo" src={eurocup3} alt="Eurocup 3"/>
                    <div className="eventPart">
                        <div className="eventWrapper">
                            <img src={austriaFlag} alt="austria flag"/><p>16-18.05.2025 RED BULL RING</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={portugalFlag} alt="portugal flag"/><p>06-08.06.2025 Portimão</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={franceFlag} alt="france flag"/><p>20-22.06.2025 PAUL RICARD</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={italyFlag} alt="italy flag"/><p>05-06.07.2025 MONZA</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={netherladsFlag} alt="netherlands flag"/><p>08-10.08.2025 ASSEN</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={belgiumFlag} alt="belgium flag"/><p>04-05.09.2025 SPA-FRANCORCHAMPS</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={spainFlag} alt="spain flag"/><p>19-21.09.2025 JEREZ</p>
                        </div>
                        <div className="eventWrapper">
                            <img src={spainFlag} alt="spain flag"/><p>14-16.11.2025 BARCELONA</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="whiteUnderline"></div>
            <div className="redUnderline"></div>
        </div>
    );
};

export default Season;