import React from "react";
import '../sass/media.css';
import instagram from '../img/icons/ig.svg';
import facebook from '../img/icons/fb.svg';
import tiktok from '../img/icons/tt.svg';
import youtube from '../img/icons/yt.svg';
import twitter from '../img/icons/x.svg';
import CustomButton from "../components/technicalComponents/CustomButton";
import {useTranslation} from "react-i18next";


const Media = () => {

    const {t} = useTranslation();
    return (
        <div className="mediaContainer">
            <div className="mediaWrapper">
                <h2>Media</h2>
                <h4>
                    {t("contactDetailsPressOfficer")}
                </h4>
                <p className="contactPressText">Mick Fiałkowski</p>
                <p className="contactPressText">Email: <span>mick@media4racing.com</span></p>

                <section className="socialMediaSection">
                    <div className="socialMediaCard">
                        <div className="socialMediaCard-flex">
                            <img src={instagram} alt="instagram logo"/>
                            <p className="socialMediaText">{t("socialMediaTextInstagram")}</p>
                        </div>
                        <div className="socialMediaCard-buttonWrapper">
                            <CustomButton label={t("buttonTextSocialMedia")} link="https://www.instagram.com/maciejgladyszofficial"/>
                        </div>
                    </div>
                    <div className="socialMediaCard">
                        <div className="socialMediaCard-flex">
                            <img src={facebook} alt="facebook logo"/>
                            <p className="socialMediaText">{t("socialMediaTextFacebook")}</p>
                        </div>
                        <div className="socialMediaCard-buttonWrapper">
                            <CustomButton label={t("buttonTextSocialMedia")} link="https://www.facebook.com/gladysz.maciej"/>
                        </div>
                    </div>
                    <div className="socialMediaCard">
                        <div className="socialMediaCard-flex">
                            <img src={youtube} alt="youtube logo"/>
                            <p className="socialMediaText">{t("socialMediaTextYouTube")}</p>
                        </div>
                        <div className="socialMediaCard-buttonWrapper">
                            <CustomButton label={t("buttonTextSocialMedia")} link="https://www.youtube.com/@maciejgladysz17"/>
                        </div>
                    </div>
                    <div className="socialMediaCard">
                        <div className="socialMediaCard-flex">
                            <img src={twitter} alt="twitter logo"/>
                            <p className="socialMediaText">{t("socialMediaTextX")}</p>
                        </div>
                        <div className="socialMediaCard-buttonWrapper">
                            <CustomButton label={t("buttonTextSocialMedia")} link="https://x.com/maciejgladysz17"/>
                        </div>
                    </div>
                    <div className="socialMediaCard">
                        <div className="socialMediaCard-flex">
                            <img src={tiktok} alt="tiktok logo"/>
                            <p className="socialMediaText">{t("socialMediaTextTikTok")}</p>
                        </div>
                        <div className="socialMediaCard-buttonWrapper">
                            <CustomButton label={t("buttonTextSocialMedia")} link="https://www.tiktok.com/@maciejgladysz_official?is_from_webapp=1&sender_device=pc"/>
                        </div>
                    </div>
                </section>
            </div>
            <div className="youTubeWrapper">
                <section className="youTubeSection">
                    <h3>YouTube</h3>
                    <div className="iframeWrapper">
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/YfG8pmLLshU?si=gSfazcBy0Ujhueqj"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/gYiWKcuW1DA?si=1IuZyEhBOav-OVHy"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/FFN1kri0rpU?si=MQCgOnAEOj5pFTuL"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/SYpqTYvOhrs?si=Xg4aDtYKzlNQT0AT"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <iframe width="560" height="315"
                                src="https://www.youtube.com/embed/TqpDvUfT-bQ?si=jPXdD-e_oJj37v2Q"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/WRm5RplF62Y?si=WNQ9BP-bGlQ8fMp0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/n91l7zLXY6c?si=rSJ4CozqbguGa94I"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/jMJSMzU3bzE?si=MENJQGoCmhmU8FUd"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/3Ibd76dV5_4?si=ZyafyCoDQTmw3IcS"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/5PwyHLqPqus?si=hp9VMssYnTmJ45wp"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/6LnME9uV5IU?si=PJC404GE4DYR5-K9"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                        </iframe>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Media;
