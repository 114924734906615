import React, {useState, useEffect} from "react";
import '../../sass/navbar.css';
import {Link} from "react-router-dom";
import logo from '../../img/Logo.svg';
import {useTranslation} from "react-i18next";
import langIcon from '../../img/icons/langIcon.svg';
import plFlag from '../../img/flag/plFlag.svg';
import engFlag from '../../img/flag/engFlag.svg';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isFlagsVisible, setIsFlagsVisible] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setIsFlagsVisible(false);
    };

    const toggleFlagsVisibility = () => {
        setIsFlagsVisible(!isFlagsVisible);
    };

    const toggleMenu = () => {
        if (isMenuOpen) {
            setIsClosing(true);
            setIsFlagsVisible(false);
            const timer = setTimeout(() => {
                setIsMenuOpen(false);
                setIsClosing(false);
                clearTimeout(timer);
            }, 700);
        } else {
            setIsMenuOpen(true);
            setIsFlagsVisible(false);
        }
    };






    return (
        <nav>
            {isFlagsVisible && <div className="backdrop" onClick={() => setIsFlagsVisible(false)}></div>}
            <div className="navbar-container">

                <div className="mobileNavContainer">
                    <Link to="/">
                        <img className="mobileLogo" src={logo} alt="Maciej Gładysz Logo"/>
                    </Link>
                    <button className="hamburger-icon" onClick={toggleMenu}>
                        ☰
                    </button>
                </div>
                <div className={`nav-links ${isMenuOpen ? 'open' : ''} ${isClosing ? 'closing' : ''}`}>
                    <Link to="/">
                        <img className="mobileLogo2" src={logo} alt="Maciej Gładysz Logo"/>
                    </Link>
                    <ul>
                        <li><Link to='/' onClick={toggleMenu}>{t("NavbarHome")}</Link></li>
                        <li><Link to='/news' onClick={toggleMenu}>{t("NavbarNews")}</Link></li>
                        <li><Link to='/maciej' onClick={toggleMenu}>{t("NavbarMaciej")}</Link></li>
                        <li><Link to='/eurocup3' onClick={toggleMenu}>{t("NavbarBolid")}</Link></li>
                        <li><Link to='/sezon2025' onClick={toggleMenu}>{t("NavbarSezon")}</Link></li>
                        <li><Link to='/media' onClick={toggleMenu}>{t("NavbarMedia")}</Link></li>
                        <li><Link to='/kontakt' onClick={toggleMenu}>{t("NavbarKontakt")}</Link></li>
                        <li onClick={toggleFlagsVisibility}>
                            <div className="langButton">
                                {t("NavbarLang")}
                                <img alt="ikona globusu" src={langIcon} />
                                {isFlagsVisible && (
                                    <div className="flagsContainer">
                                        <p>Select your language</p>
                                        <div className="flagWrapper">
                                            <img className="flagIcon" src={plFlag} alt="Polska" onClick={() => toggleLanguage('pl')} />
                                            <img className="flagIcon" src={engFlag} alt="Angielski" onClick={() => toggleLanguage('en')} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="nav-whiteUnderline"></div>
            <div className="nav-redUnderline"></div>
        </nav>

    );
};


export default Navbar;